import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/logo.svg'

function Thankyou() {
  return (
    <div className='thankyou'>
     <img src={logo}/>
     <br/>
    <h1>THANK YOU!</h1>
    <p>Your booking has been received and we will contact you soon.</p>
    <Link to='/'><p>Back To Home</p></Link>

    </div>
  )
}

export default Thankyou
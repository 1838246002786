import React from 'react'
import icon1 from '../Assets/Group 28.svg'
import icon2 from '../Assets/Group 29.svg'
import icon3 from '../Assets/Group 30.svg'
import icon4 from '../Assets/Group 31.svg'
import icon5 from '../Assets/Group 32.svg'
import icon6 from '../Assets/Group 33.svg'
import logo from '../Assets/logo.svg'

function Navbar() {
  return (
    <>
        <div className='topnav'>
        <ul>
            <li>
                <img src={icon1}/>
                +91-7838779980
            </li>
            <li>
                <img src={icon2}/>
                freezemytrip@gmail.com
            </li>
        </ul>
        <ul>
        <li>

            <img src={icon3}/>
        </li>
            <li>
            
            <img src={icon4}/>
        </li>
            <li>
            
            <img src={icon5}/>
        </li>
            <li>
            
            <img src={icon6}/>
        </li>

        </ul>

        </div>
        <div className='nav'>
        <img src={logo}/>
        {/* <ul>
            <li>Home</li>
            <li>About us</li>
            <li>Package</li>
            <li>Offer</li>
            <li>Gallery</li>
            <li>Contact </li>


        </ul> */}
        <div className='calls'>
        <div className='callicon'>
        <i class="bi bi-telephone-fill"></i>
        </div>
        <a className='cont' style={{textDecoration:'none' , color:'black'}} href="tel:+917838779980">+91-7838779980</a>
        </div>

        </div>
    </>
  )
}

export default Navbar
import React, { useState ,useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from '../Components/Navbar'
import img1 from '../Assets/a.svg'
import img2 from '../Assets/57541f38182587.57582bb408c99.jpg'
import iocn from '../Assets/SVG.svg'
import img3 from '../Assets/img-hotel.png.svg'
import de1 from '../Assets/destination.png.svg'
import support from '../Assets/support.png.svg'
import d2 from '../Assets/Paragraph+Background+Border.png'
import d3 from '../Assets/Paragraph+Background+Border1.png'
import author from '../Assets/author.png.png'
import Footer from '../Components/Footer'
import img22 from '../Assets/59919709d719c.jpg'
import img33 from '../Assets/IMG_8199-e1694547043501.jpeg'
import img44 from '../Assets/Winter-in-Azerbaijan-Feature-.jpg'
import g1 from '../Assets/g (1).jpg'
import g2 from '../Assets/g (2).jpg'
import g3 from '../Assets/g (3).jpg'
import g4 from '../Assets/g (4).jpg'
import g5 from '../Assets/g (5).jpg'
import g6 from '../Assets/g (6).jpg'
import baku1 from '../Assets/baku1.jpeg'
import baku2 from '../Assets/baku2.jpeg'
import baku3 from '../Assets/baku3.jpeg'
import baku4 from '../Assets/baku4.jpeg'
import pack1 from '../Assets/pack1.webp'
import pack2 from '../Assets/pack2.jpg'
import pack3 from '../Assets/pack3.jpg'
import pack4 from '../Assets/pack4.webp'
import pack5 from '../Assets/pack5.jpg'
import pack6 from '../Assets/pack6.webp'
import pack7 from '../Assets/pack7.jpeg'
import pack8 from '../Assets/pack8.jpeg'
import pack9 from '../Assets/pack9.jpg'
import pack10 from '../Assets/pack10.jpg'
import whatsapp from '../Assets/WhatsApp_icon.png.webp'

import { Modal ,ModalBody ,ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

function Home() {
  const [modal , setModal] = useState(false)
  
  const [modal1 , setModal1] = useState(false)

  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_5pil9lv', 'template_quhvl3l', form.current, {
        publicKey: 'qU1BfQ8GjQgrUFnRq',
      })
      .then(
        () => {
          // window.alert('Thank you! Your booking has been received.');
          setModal1(false); // Close the modal
          form.current.reset();
          window.location.href = '/thankyou';
        },
        (error) => {
          window.alert('Oops! Something went wrong. Please try again.');
        }
      );
  };




  const [selectedPackage, setSelectedPackage] = useState(null);
  const handleViewMore = (pkg) => {
    setSelectedPackage(pkg);
    setModal(true);
  };

  const handleBookNow = (pkg) => {
    setSelectedPackage(pkg);
    setModal1(true);
  };

  const [activeModal, setActiveModal] = useState(null);

  const openModal = (index) => {
    setActiveModal(index);
  };

  const closeModal = () => {
    setActiveModal(null);
  };
  const packages = [
    {
      title: "04 Nights / 05 Days Package - Glimpses of Azerbaijan",
      location: "Baku, Gobustan, Shahdag, Absheron",
      duration: "4 Nights / 5 Days",
      price: "INR 25,999/-",
      imgSrc: pack1,
      details: [
        "Day 01: Arrival Baku + Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour",
        "Day 03: Baku to Fire Mountain & Fire Temple Tour",
        "Day 04: Shahdag day trip With Cable Car Tickets",
        "Day 05: Departure Drop to Airport",
        "04 Nights Hotel Stay in Baku",
        "Sightseeing in districts (Baku, Gobustan, Shahdag, Absheron)",
        "Entrance Tickets for Gobustan Museum, Taxi to Mud Volcano, Fire Temple, Fire Mountain",
        "Shahdag Day trip with Cable Car Tickets",
        "All tours and transfers on PVT basis as per the itinerary",
        "Azerbaijan Visa"
      ],
    },
    {
      title: "05 Nights / 06 Days Package - Baku & Shahdag with Hotel Stay",
      location: "Baku, Shahdag, Absheron, Gobustan",
      duration: "5 Nights / 6 Days",
      price: "INR 29,999/-",
      imgSrc: pack2,
      details: [
        "Day 01: Arrival Baku & Morning Half Day Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour",
        "Day 03: Fire Mountain + Fire Temple Tour",
        "Day 04: Baku to Shahdag Transfer and Hotel Stay in Shahdag Hotel & Spa",
        "Day 05: Shahdag to Baku Transfer & Shopping in Nizami Street",
        "Day 06: Departure Drop to Airport",
        "Accommodation including Breakfast",
        "04 Nights in Baku Hotel, 01 Night in Shahdag Hotel",
        "Sightseeing in districts (Baku, Shahdag, Absheron, Gobustan)",
        "Entrance Fees: Cable Car (1 line) Shahdag, Fire Temple, Fire Mountain, Gobustan Museum, Taxi to Mud Volcano",
        "All transfers according to the program, including airport transfer and tour in Mercedes Viano",
        "Azerbaijan Visa"
      ],
    },
    {
      title: "05 Nights / 06 Days Package - Baku + Gabala Stay Package",
      location: "Baku, Absheron, Gabala, Gobustan",
      duration: "5 Nights / 6 Days",
      price: "INR 35,999/-",
      imgSrc: pack3,
      details: [
        "Day 01: Arrival Baku + Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour",
        "Day 03: Baku to Fire Mountain & Fire Temple Tour",
        "Day 04: Baku - Gabala including enroute Juma Mosque + Gabala Local Sightseeing with Cable Car + Tufandag Mountains & stay in Gabala",
        "Day 05: Transfer from Gabala to Baku + Hyder Aliyev Museum Tour",
        "Day 06: Departure Drop to Airport",
        "04 Nights in Baku Hotel, 01 Night in Gabala Hotel",
        "Sightseeing in districts (Baku, Absheron, Gabala, Gobustan)",
        "Entrance Fees: Fire Temple, Fire Mountain, Gobustan Museum, Mud Volcano, Cable Car (2 lines) in Gabala",
        "All transfers according to the program, including airport transfers",
        "Azerbaijan Visa"
      ],
    },
    {
      title: "06 Nights / 07 Days Package - Discover Azerbaijan Gateway",
      location: "Baku, Gobustan, Shahdag, Gabala, Absheron",
      duration: "6 Nights / 7 Days",
      price: "INR 43,999/-",
      imgSrc: pack4,
      details: [
        "Day 01: Arrival Baku + Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour",
        "Day 03: Baku to Fire Mountain & Fire Temple Tour",
        "Day 04: Shahdag day trip with Shahdag Cable car + Shahdag Coaster ride",
        "Day 05: Baku - Gabala including enroute Juma Mosque + Gabala Local Sightseeing with Cable Car + Tufandag Mountains & stay in Gabala",
        "Day 06: Transfer from Gabala to Baku + Hyder Aliyev Museum tour",
        "Day 07: Departure drop to Airport",
        "05 Nights in Baku Hotel, 01 Night Stay in Shahdag Hotel",
        "Sightseeing in districts (Baku, Gobustan, Shahdag, Gabala, Absheron)",
        "Entrance: Gobustan Museum, Taxi to Mud Volcano, Fire Temple, Fire Mountain, Gabala Cable Car (2 lines), Roller Coaster, Haydar Aliyev Museum",
        "English speaking guide",
        "1 bottle of water per day (0.5 lt)",
        "All tours and transfers on PVT basis as per the itinerary with Mercedes",
        "Azerbaijan Visa"
      ],
    },
    {
      title: "06 Nights / 07 Days Package - Classic Azerbaijan with Gabala Stay",
      location: "Baku, Gobustan, Shahdag, Gabala, Absheron",
      duration: "6 Nights / 7 Days",
      price: "INR 45,999/-",
      imgSrc: pack5,
      details: [
        "Day 01: Arrival Baku + Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour",
        "Day 03: Baku to Fire Mountain & Fire Temple Tour",
        "Day 04: Baku - Gabala including enroute Juma Mosque + Gabala Local Sightseeing with Cable Car + Tufandag Mountains & stay in Gabala",
        "Day 05: Gabala – Sheki tour – The Palace, Street of Sweets & Potteries",
        "Day 06: Transfer from Gabala to Baku + Hyder Aliyev Museum tour",
        "Day 07: Departure Drop to Airport",
        "04 Nights in Baku Hotel, 02 Nights Stay in Gabala Hotel",
        "Sightseeing in districts (Baku, Gobustan, Shahdag, Gabala, Absheron)",
        "Entrance: Gobustan Museum, Taxi to Mud Volcano, Fire Temple, Fire Mountain, Gabala Cable Car (2 lines), Haydar Aliyev Museum",
        "English speaking guide",
        "1 bottle of water per day (0.5 lt)",
        "All tours and transfers on PVT basis as per the itinerary",
        "Azerbaijan Visa"
      ],
    },
    {
      title: "06 Nights / 07 Days Package - Discover Azerbaijan with Gabala",
      location: "Baku, Gobustan, Shahdag, Gabala, Absheron",
      duration: "6 Nights / 7 Days",
      price: "INR 47,999/-",
      imgSrc: pack6,
      details: [
        "Day 01: Arrival Baku + Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour & Fire Mountain",
        "Day 03: Shahdag day trip With Cable Car Tickets",
        "Day 04: Baku - Gabala including enroute Juma Mosque + Gabala Local Sightseeing with Cable Car + Tufandag Mountains & stay in Gabala",
        "Day 05: Gabala – Sheki tour – The Palace, Street of Sweets & Potteries",
        "Day 06: Transfer from Gabala to Baku + Hyder Aliyev Museum tour",
        "Day 07: Departure Drop to Airport",
        "04 Nights in Baku Hotel, 02 Nights Stay in Gabala Hotel",
        "Sightseeing in districts (Baku, Gobustan, Shahdag, Gabala, Absheron)",
        "Entrance: Gobustan Museum, Taxi to Mud Volcano, Fire Temple, Fire Mountain, Gabala Cable Car (2 lines), Haydar Aliyev Museum",
        "English speaking guide",
        "1 bottle of water per day (0.5 lt)",
        "All tours and transfers on PVT basis as per the itinerary",
        "Azerbaijan Visa"
      ],
    },
    {
      title: "07 Nights / 08 Days Package - Incredible Azerbaijan Baku + Gabala + Shahdag",
      location: "Baku, Gobustan, Shahdag, Gabala, Absheron",
      duration: "7 Nights / 8 Days",
      price: "INR 52,999/-",
      imgSrc: pack7,
      details: [
        "Day 01: Arrival Baku + Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour",
        "Day 03: Baku - Gabala including enroute Juma Mosque + Gabala Local Sightseeing with Cable Car + Tufandag Mountains & stay in Gabala",
        "Day 04: Gabala – Sheki tour – The Palace, Street of Sweets & Potteries",
        "Day 05: Gabala to Baku Transfer & Evening Tour of Nizami Street",
        "Day 06: Fire Mountain & Fire Temple Tour",
        "Day 07: Shahdag day trip with Shahdag Cable car + Shahdag Coaster ride",
        "Day 08: Departure Drop to Airport",
        "05 Nights in Baku Hotel, 02 Nights Stay in Gabala Hotel",
        "Sightseeing in districts (Baku, Gobustan, Shahdag, Gabala, Absheron)",
        "Entrance: Gobustan Museum, Taxi to Mud Volcano, Fire Temple, Fire Mountain, Gabala Cable Car (2 lines), Roller Coaster, Haydar Aliyev Museum",
        "English speaking guide",
        "1 bottle of water per day (0.5 lt)",
        "All tours and transfers on PVT basis as per the itinerary with Mercedes",
        "Azerbaijan Visa"
      ],
    },
    {
      title: "04 Nights / 05 Days Package Glimpses of Azerbaijan ",
      location: "Glimpses of Azerbaijan ",
      duration: "05 Nights / 06 Days Package ",
      price: "INR 75,999/-",
      air: "with Airfare",

      imgSrc: pack10,
      details: [
        "Day 01 : Arrival Baku + Baku City Tour ",
        "Day 02: Gobustan & Mud Volcano Tour",
        "Day 03 : Baku to Fire Mountain & Fire Temple Tour",
        "Day 04: Shahdag day trip With Cable Car Tickets ",
        "Day 05: Departure Drop to Airport",
        "	Airfare from Delhi-Baku-Delhi ",
        
        "	Return Airport Transfers ",

        "	04 Nights Hotel Stay in Baku ",
        "	Sightseeing in districts (Baku, gobustan  ,shahdag, absheron)",
        "Shahdag Day trip with Cable Car Tickets",
        "	02 Night Stay in Gabala Hotel  ",
        "Entrances Tickets of Gobustan Museum &taxi to mud volcano, fire temple-fire mountain ",
        "All tours and transfers on PVT basis as per the itinerary",
        "	Azerbaijan Visa ",
       

      ],
    },
    {
      title: "05 Nights / 06 Days Package Baku + Gabala Stay Package ",
      location: "Baku + Gabala Stay",
      duration: "05 Nights / 06 Days Package ",
      price: "INR 85,999/-",
      air: "with Airfare",

      imgSrc: pack8,
      details: [
        "Day 01: Arrival Baku + Baku City Tour",
        "Day 02:Gobustan & Mud Volcano Tour",
        "Day 03: : Baku to Fire Mountain & Fire Temple Tour",
        "Day 04: Baku -Gabala including enroute Juma Mosque + Gabala Local Sight-seeing with cable car + Tufandag Mountains & stay in Gabala ",
        "Day 05: Transfer from Gabala to Baku + Hyder Aliyvev Museum tour ",
        "Day 06: Departure Drop to Airport",
        
        "Airfare from Delhi-Baku-Delhi ",
        "Accommodation in Hotel including breakfast )",
        "04 Night stay in Baku Hotel ",
        "01 Night stay in Gabala Hotel",
        "Sightseeing in districts (Baku ,absheron, Gabala, gobustan)",
        "All transfers according to program including airport transfers",
        "Azerbaijan Visa "
      ],
    },
    {
      title: "06 Nights / 07 Days Package Discover Azerbaijan With Gabala  ",
      location: "Discover Azerbaijan With Gabala ",
      duration: "05 Nights / 06 Days Package ",
      price: "INR 95,999/-",
      air: "with Airfare",

      imgSrc: pack9,
      details: [
        "Day 01 : Arrival Baku + Baku City Tour",
        "Day 02: Gobustan & Mud Volcano Tour & Fire Mountain",
        "Day 03 : Shahdag day trip With Cable Car Tickets",
        "Day 04: Baku -Gabala including enroute Juma Mosque + Gabala Local Sight-seeing with cable car + Tufandag Mountains & stay in Gabala  ",
        "Day 05: Gabala – Sheki tour – The Palace, Street of Sweets & Potteries",
        "Day 06:  Transfer from Gabala to Baku + Hyder Aliyvev Museum tour ",
        
        "Day 07: Departure Drop to Airport",

        "	Airfare from Delhi-Baku-Delhi ",
        "	Airport Pick up & Drop ",
        "	04 Nights in stay in Baku Hotel",
        "	02 Night Stay in Gabala Hotel  ",
        "Sightseeing in districts (Baku, gobustan  ,shahdag,gabala,absheron  )",
        "Entrances : Gobustan museum  &taxi to mud volcano , fire temple-fire mountain ,Gabala cable car (2lines) ,haydar aliyev museum ",
        "	English speaking guide ",
        "	1bottles of water per day (0,5lt) ",
        "	All tours and transfers on PVT basis as per the itinerary ",
        "	Azerbaijan Visa ",

      ],
    },
  
  ];

  return (
    <>
    <Link to='https://api.whatsapp.com/send?phone=917838779980'>

    <img className='whatsapp' src={whatsapp}/>
    </Link>
      <Navbar/>
     <div className='overlay'>
     <div className='banner'>
      <div className='banner-left'>
      <div className='disc-div'>
<p>Discovery the World</p>

      </div>
      <br/>
      <h2>Unleash Your Wanderlust<br/>
Book Your Next Journey</h2>
<br/>
<p>Crafting Exceptional Journeys: Your Global Escape Planner. Unleash Your Wanderlust:<br/>
Seamless Travel, Extraordinary Adventures</p>

      </div>
      <div  className='banner-right'>
      <div className='book-form'>
      <h3>Get In Touch</h3>
      <form ref={form} onSubmit={sendEmail}>
      <input name='user_name' placeholder='Your Name'/>
      <input name='user_mobile' placeholder='Your Mobile No.'/>
      <input name='user_email' placeholder='Your E-mail'/>
      <input name='user_message' placeholder='Message..'/>
<button type="submit">Send</button>
      </form>

      </div>
        
      </div>

      </div>
     </div>
   
      <br/>
      <br/>
      <div className='specialpackges'>
      <h2><img src={iocn}/>Special Travel Packges</h2>
      <p>Quality as judged by customers. Book at the ideal price!</p>
      <br/>
      <br/>
      <div>
      <div className="plan-cards-container">
      {packages.map((pkg, index) => (
        <div key={index} className="plan-card">
          <div className='rates'>
            <h4>{pkg.price}</h4>
            <h4>{pkg.air}</h4>
          </div>
          <div className="plan-img">
            <img src={pkg.imgSrc} alt={`Image for ${pkg.title}`} />
          </div>
          <div className="plan-text">
            <div className="luxury">
              <p>Luxury</p>
            </div>
            <br />
            <h2>{pkg.title}</h2>
            <br />
            <p className="locat">
              <i className="bi bi-geo-alt-fill"></i> {pkg.location}
            </p>
            <p className="locat">
              <i className="bi bi-stopwatch"></i> {pkg.duration}
            </p>
            <br />
            <div className="viewplan">
              <button className='booknow' onClick={() => handleBookNow(pkg)}>Book Now</button>
              <button className='viewdtl' onClick={() => handleViewMore(pkg)}>View More</button>
            </div>
          </div>

          <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={() => setModal(!modal)}>
              {selectedPackage?.title}
            </ModalHeader>
            <ModalBody>
              <p><strong>Location:</strong> {selectedPackage?.location}</p>
              <p><strong>Duration:</strong> {selectedPackage?.duration}</p>
              <br />
              <h4>{selectedPackage?.price} / person</h4>
              <br />
              <h5>Inclusions:</h5>
              <ul>
                {selectedPackage?.details.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
              </ul>
              <button className='booknow booknow1' onClick={() => handleBookNow(pkg)}>Book Now</button>
            </ModalBody>
          </Modal>
          {/*  */}
{/*  */}
<Modal size="lg" isOpen={modal1} toggle={() => setModal1(!modal1)}>
      <ModalHeader toggle={() => setModal1(!modal1)}>
        Discover Baku - Book Your Travel Pack Now!
      </ModalHeader>
      <ModalBody>
        {message && (
          <div className={`alert alert-${messageType}`}>
            {message}
          </div>
        )}
        <form ref={form} onSubmit={sendEmail}>
          <div className='Ips'>
            <input name='user_name' placeholder='Your Name' />
            <input name='user_mobile' placeholder='Your Mobile No.' />
          </div>
          <div className='Ips'>
            <input name='user_email' placeholder='Your E-mail' />
          </div>
          <textarea name='user_message' placeholder='Message...'></textarea>
          <button type="submit" className='modalbook'>Book Now</button>
        </form>
      </ModalBody>
    </Modal>
        </div>
      ))}
    </div>

<br/>
<br/>





  
    </div>

















      {/* <div className='specialpackges-cards'>
      <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 1</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
  <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 2</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
      </div>
      <br/>
      <br/>

      <div className='specialpackges-cards'>
      <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 3</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
  <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 4</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
      </div>
      <br/>
      <br/>

      <div className='specialpackges-cards'>
      <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 5</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
  <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 6</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
      </div>
      <br/>
      <br/>

      <div className='specialpackges-cards'>
      <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 7</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
  <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 8</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
      </div>

      <br/>
      <br/>

      <div className='specialpackges-cards'>
      <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 9</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
  <div className='specialpackges-card'>
      <img src={img3}/>
      <div className='specialpackges-details'>
      <div className='luxury'>
    <p>Luxury</p>
      </div>
      <br/>
<h3>Packges 10</h3>
<p className='locat'><i class="bi bi-geo-alt-fill"></i> Singapore</p>
<p className='locat'><i class="bi bi-stopwatch"></i> 6D/5N</p>
<br/>
<div className='viewplan'>
<h4>14755 <span>/ night</span></h4>
<button>View More</button>
</div>


      </div>

</div>
      </div> */}



      </div>
      <br/>
      <br/>
     
      <div className='PopularDestinations'>
      <h2>Best time to travel Azerbaijan</h2>
      <p>The Perfect Seasons to Experience Its Beauty and Charm</p>
      <br/>
      <br/>
      <div className='PopularDestinations-cards'>
      <div className='PopularDestinations-card'>
      <img src={img2}/>
      <br/>
      <div>
        <h3>April to June (Spring)</h3>
        <p>Azerbaijan</p>
      </div>

</div>
<div className='PopularDestinations-card'>
      <img src={img22}/>
      <br/>
      <div>
        <h3>September to October (Fall)</h3>
        <p>Azerbaijan</p>
      </div>

</div>
<div className='PopularDestinations-card'>
      <img src={img33}/>
      <br/>
      <div>
        <h3>July to August (Summer)</h3>
        <p>Azerbaijan</p>
      </div>

</div>
<div className='PopularDestinations-card'>
      <img src={img44}/>
      <br/>
      <div>
        <h3>November to March (Winter)</h3>
        <p>Azerbaijan</p>
      </div>

</div>
      </div>
      </div>
      <br/>
      <br/>
      <div className='whyus'>
      <div className='whyus-left'>
    
      <div className='bakuimg'>
      <img src={baku2}/>
      <img src={baku1}/>
      <img src={baku3}/>
      <img src={baku4}/>


      </div>
      <br/>
      <div className='WhyChooseUs'>
        <p>Baku</p>
      </div>
      <br/>

      <h2>Where History Meets Modernity Along the Caspian Sea</h2>
      <br/>

      <h4>Baku, the vibrant capital of Azerbaijan, seamlessly blends history and modernity along the picturesque Caspian Sea. As the world's lowest-lying national capital, it serves as a cultural, scientific, and industrial hub, home to world-class museums, galleries, and the iconic Heydar Aliyev Center. The city's well-connected transport network makes its stunning attractions, like the scenic Baku Boulevard and nearby Gobustan National Park, easily accessible. With a rich cultural heritage, dynamic arts scene, and breathtaking landscapes, Baku offers unforgettable experiences, making it a must-visit destination.</h4>

</div>
    {/* <div className='whyus-right'>
    <div className='de1'>
    <div className='de-div de-div1'>
    <img src={de1}/>
    <h3>4500+ Destination</h3>
    <p>Our expert team handpicked all
    destinations in this site.</p>

</div>
  <div className='' >
  <img src={d2}/>
</div>

    </div>
    <div className='de2'>
    <div className='de-div de-div3'>
    <img src={support}/>
    <h3>Great 24/7 Support</h3>
    <p>We are here to help, before,
    during, and even after your trip.</p>


</div>
  <div className=''>
  <img src={d3}/>

</div>

    </div>

</div> */}

      </div>
      <br/>
      <br/>
      <div className='gallery'>
      <div className='WhyChooseUs'>
        <p>Our Tour Gallery</p>
      </div>
      <br/>
      <h2>Journey to the Most<br/>
      Beautiful Places on Earth</h2>
      <br/>
      <br/>
      <br/>
      <div className='gallery-div'>
        <img src={g1}/>
        <img src={g2}/>
        <img src={g3}/>
        <img src={g4}/>
        <img src={g5}/>
        <img src={g6}/>
      </div>

      </div>
    
      <div className='testimonials'>
      <div className='testimonials-left'>
      <div className='WhyChooseUs'>
        <p>Testimonials</p>
      </div>
      <br/>
{/* 
      <h2>What our clients are<br/>
      saying about us?</h2>
      <br/>
      <p>Discover how you can offset your adventure's carbon emissions
and support the sustainable initiatives practiced by our
operators worldwide.</p> */}

</div>
      <div className='testimonials-right'>
      <div className='review'>
      <div className='review-heading'>
      <img src={author}/>
      <h3>Amit Sharma</h3>

      </div>
      <br/>
      <p>My Baku trip organized by Freeze My Trip was nothing short of extraordinary. The itinerary was perfectly planned, allowing us to explore the rich culture and history of this beautiful city. The accommodations were top-notch, and the local guides were incredibly knowledgeable. I can't wait to book my next adventure with them!</p>


      </div>
      <div className='review'>
      <div className='review-heading'>
      <img src={author}/>
      <h3>Priya Menon</h3>

      </div>
      <br/>
      <p>Freeze My Trip made our Baku vacation absolutely stress-free. From the moment we landed to the time we departed, everything was taken care of. The attention to detail and the personal touches made us feel special throughout the trip. I highly recommend Freeze My Trip for anyone looking to explore Baku!</p>


      </div>
      <div className='review'>
      <div className='review-heading'>
      <img src={author}/>
      <h3>Rohit Gupta</h3>

      </div>
      <br/>
      <p>Our trip to Baku with Freeze My Trip was filled with delightful surprises. The team curated an experience that went beyond the typical tourist spots, introducing us to hidden gems that only locals know about. Their expertise and passion for travel truly shone through. This was a trip to remember</p>


      </div>
    
</div>




   
      </div>
      <Footer/>
   

    </>
  )
}

export default Home
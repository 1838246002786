import React from 'react'
import logo from '../Assets/logo.svg'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <>
        <div className='footer'>
        <ul>
            <img src={logo}/>
          
          
        </ul>
        <ul>
            <h3>Need help? Call us <span><a style={{textDecoration:'none', color:'rgba(253, 206, 11, 1)'}} href="tel:+917838779980">+91-7838779980</a></span></h3>
        </ul>
        

        </div>
        <div className='bottom-footer'>
        <h2>© 2024 Freezmy trip Inc. All rights reserved.Designed & Developed by <Link style={{color:'white', fontWeight:'800' , textDecoration:'none'}} to='https://www.strixdigital.in/'>Strix Digital</Link></h2>
        <ul>
            <li>Home</li>
            <li>About</li>
            <li>Contact Us</li>
            <li>Packages</li>

        </ul>

        </div>
    </>
  )
}

export default Footer
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import 'bootstrap/dist/css/bootstrap.css'
import Thankyou from "./Pages/Thankyou";
function App() {
  return (
 <>
   <Router>
 <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="/thankyou" element={<Thankyou/>} />

   

 </Routes>
</Router>
 </>
  );
}

export default App;
